@media (max-width: 1440px) {
  .how-works-item {
    padding: 20px;
  }
  .how-works-item-con-title {
    font-size: 20px;
  }
  .rent-detail-info {
    gap: 30px;
  }
}

@media (max-width: 1250px) {
  .find-car-filter {
    width: 100%;
  }
  .find-car-filter-input.input-text input {
    width: 100%;
  }
  .find-car-filter-input.input-date input {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .footer .footer__inner .footer__inner-items .footer__inner-item:first-child {
    max-width: 230px;
  }
  .rent-detail-info-gallery {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .rent-detail-info-gallery img:first-child {
    grid-row: 1 / 3;
    grid-column: 1 / 5;
  }
  .rent-detail-info-gallery img:nth-child(2) {
    grid-row: 3 / 5;
    grid-column: 1 / 3;
  }
  .rent-detail-info-gallery img:nth-child(3) {
    grid-row: 3 / 5;
    grid-column: 3 / 5;
  }
}
@media (max-width: 1024px) {
  .footer
    .footer__inner
    .footer__inner-items:last-child
    .footer__inner-item
    + .footer__inner-item {
    margin-left: 0px;
  }
  .footer .footer__inner .footer__inner-items {
    flex-wrap: wrap;
    gap: 30px;
  }
  .header__inner-link {
    margin-left: 30px;
    a + a {
      margin-left: 15px;
    }
  }
  .header__inner-nav .nav-list > li + li {
    margin-left: 25px;
  }
  .header__inner-nav .nav-list > li a {
    font-size: 18px;
    line-height: 25px;
  }
  .header__inner-link a {
    font-size: 18px;
    line-height: 25px;
  }
  .how-works-head {
    margin-right: 0px;
    margin: 0 auto;
  }
  .global-title {
    font-size: 40px;
  }
  p {
    font-size: 16px;
  }
  .car-card-img {
    height: 200px;
  }
  .car-card-info-book {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .way-we-box-item-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .rent-list-cars-items {
    gap: 20px;
  }
  .book__inner {
    gap: 30px;
  }
  .payment__form-con-box {
    gap: 20px;
  }
  .rent-detail-info-panel-title {
    font-size: 40px;
  }
  .header__inner-logo a {
    width: 100px;
  }
}
@media (max-width: 900px) {
  .find-car {
    padding-bottom: 30px;
  }
  .find-car.rent {
    padding-top: 30px;
    .find-car-filter {
      margin-top: 0px;
    }
  }
  .find-car-filter {
    position: static;
    transform: none;
    margin-top: 30px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .car-slider-swiper .swiper {
    padding-bottom: 40px;
  }
  .car-slider {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .big-btn {
    padding: 10px 0;
    max-width: 178px;
    font-size: 18px;
    line-height: 24px;
  }
  .find-car__inner-box {
    padding-top: 60px;
  }
  .find-car-title {
    font-size: 35px;
    line-height: 45px;
    min-height: 130px;
  }
  .find-car-text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  body {
    font-size: 14px;
  }
  .footer
    .footer__inner
    .footer__inner-items:first-child
    .footer__inner-item
    ul
    li {
    margin-top: 0px;
  }
  .footer
    .footer__inner
    .footer__inner-items:first-child
    .footer__inner-item
    ul
    li
    + li {
    margin-top: 20px;
  }
  .footer .footer__inner .footer__inner-items:first-child .footer__inner-item {
    margin-top: 0px;
  }
  .footer .footer__inner .footer__inner-top {
    flex-direction: column;
    gap: 30px;
  }
  .header__inner {
    min-height: 80px;
  }
  .header__inner .header__inner-logo a {
    width: 90px;
  }
  .header__menu-btn {
    display: block;
    z-index: 2;
    margin-right: unset;
  }
  .header__inner.active {
    .header__menu-btn span {
      background: $black;
    }
    .header__inner-link {
      a svg path:first-child {
        fill: $white;
      }
      a svg path:last-child {
        stroke: $white;
      }
    }
  }
  .header__inner-nav .global-btn {
    display: block;
    width: 100%;
  }
  .header__inner-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $white;
    left: 0;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 0%;
    padding: 60px 20px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100%);
  }
  .header__inner-nav.active {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 2;

    .nav-list-item.drop-down {
      .nav-list-item-btn {
        color: $white;
        svg path {
          fill: $white;
        }
      }
    }
    .nav-list-item.drop-down.active {
      .nav-list-item-drop-down {
        position: static;
        background-color: transparent;
        display: flex;
        ul li a {
          text-align: left;
        }
      }
    }
    .nav-list {
      flex-direction: column;
      width: 100%;
      margin: auto 0px;
      align-items: flex-start;
      overflow-y: auto;
      > a {
        margin-left: 0px;
        margin-top: 15px;
      }
      .nav-list-item {
        a {
          width: 100%;
          color: $black;
          display: block;
          text-align: center;
        }
      }
      .nav-list-item + .nav-list-item {
        margin-left: 0px;
        margin-top: 15px;
      }
    }

    .nav-list .nav-list-item .nav-list-item-drop-down ul li a {
      white-space: normal;
    }

    .nav-list-item-drop-down {
      max-width: 100%;
      transition: none !important;
      display: none;
    }
  }

  .footer .footer__inner .footer__inner-bottom .footer__inner-bottom-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .rent-card-box-items {
    gap: 15px;
    height: 100px;
  }
  .rent-card-box-item img {
    height: 28px;
  }
  .rent-card {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  p {
    font-size: 14px;
  }
  .global-title {
    font-size: 35px;
  }
  .how-works-head .how-works-text {
    margin-bottom: 30px;
  }
  .how-works-item {
    min-height: 150px;
  }
  .how-works-items {
    margin-bottom: 30px;
  }
  .how-works-img img {
    object-fit: cover;
    opacity: 0.2;
  }
  .services {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .services__inner {
    gap: 50px;
  }
  .services-item .services-item-text {
    font-size: 14px;
  }
  .services-item .services-item-title {
    font-size: 18px;
  }
  .services-text p {
    font-size: 14px;
  }
  .contact {
    padding: 40px 0px;
  }
  .contact-box {
    flex-direction: column;
  }
  .contact-box-img {
    order: -1;
    max-width: 100%;
    height: 200px;
  }
  .contact-box-img img {
    object-position: bottom;
  }
  .contact-box-form {
    max-width: 100%;
  }
  .contact-box {
    padding: 20px;
  }
  .terms-service {
    margin: 40px 0px;
  }
  .terms-service-text span {
    margin: 20px 0px;
    font-size: 18px;
  }
  .terms-service-text p + p {
    margin-top: 20px;
  }
  .way-we-box {
    flex-direction: column;
  }
  .way-we-box-img {
    height: unset;
  }
  .way-we {
    margin-bottom: 40px;
  }
  .rent-list-cars-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .book__inner {
    flex-direction: column;
  }
  .book-form {
    order: 1;
    max-width: 100%;
  }
  .book-rental {
    max-width: 100%;
  }
  .book {
    margin-bottom: 40px;
  }

  .rent-detail-info {
    flex-direction: column;
  }
  .rent-detail-info-panel {
    max-width: 100%;
  }
  .rent-detail-info-panel-inputs {
    max-width: 100%;
  }
  .rent-detail-info-text {
    gap: 20px;
  }
  .rent-detail-info-text span {
    font-size: 18px;
  }
  .header__inner-link {
    margin-left: auto;
    margin-right: 20px;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 0 15px;
  }
  .services-items {
    flex-direction: column;
  }
  .footer
    .footer__inner
    .footer__inner-items
    .footer__inner-item
    .footer__inner-item-title {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .footer {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .footer .footer__inner .footer__inner-logo {
    margin-bottom: 20px;
  }

  .footer
    .footer__inner
    .footer__inner-items
    .footer__inner-item
    .footer__inner-item-title {
    font-size: 16px;
  }
  .rent-card-box-top-title {
    font-size: 18px;
  }
  .rent-card-box {
    gap: 20px;
  }
  .book-rental-car {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .rent-detail-info-gallery {
    height: 400px;
  }
  .rent-detail-info-panel-inputs {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .payment__form-con-checkbox .payment__form-con-checkbox-itme {
    padding: 10px 10px;
  }
  .rent-list-cars-items {
    grid-template-columns: repeat(1, 1fr);
  }
  .global-title {
    font-size: 30px;
  }
  .how-works-head .how-works-title h2 {
    padding: 0 0px;
  }
  .how-works-item-icon {
    max-width: 100%;
    height: 40px;
  }
  .how-works-item {
    flex-direction: column;
  }
  .find-car-filter {
    grid-template-columns: repeat(1, 1fr);
  }
  .find-car-title {
    font-size: 27px;
    line-height: 37px;
    margin-bottom: 10px;
  }
  .find-car-text {
    font-size: 16px;
    line-height: 25px;
  }
  .book-rental-total {
    flex-direction: column;

    align-items: flex-start;
    gap: 20px;
  }
  .header__inner .header__inner-logo a {
    width: 70px;
  }
  .header__inner-link a {
    font-size: 15px;
    line-height: 25px;
  }
  .header__inner-link {
    margin-right: 10px;
  }
  .header__inner-link a + a {
    margin-left: 10px;
  }
}
