body.hiden {
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  background: $white;
  color: $black;
}
p {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: $dark-text;
  letter-spacing: -0.02em;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
.global-title {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $dark-text;
  padding-bottom: 8px;
  border-bottom: 2px solid $orange;
  width: fit-content;
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main {
  flex: 1 1 auto;
}
.container {
  max-width: 1770px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.header__menu-btn {
  display: none;
  margin-right: auto;
  width: 25px;
  height: 21px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.header__menu-btn span {
  display: block;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
  width: 100%;
  background: $black;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2),
.header__menu-btn span:nth-child(3) {
  top: 9px;
  width: 100%;
}

.header__menu-btn span:nth-child(4) {
  top: 19px;
  width: 100%;
  right: 0;
  left: inherit;
}
.header__menu-btn.open span {
  border-radius: 10px;
}
.header__menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
  opacity: 0;
  display: none;
}

.header__menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  opacity: 0;
  display: none;
}

.btn {
  border-radius: 5px;
  background: $black;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  color: $white;
  padding: 15px;
  min-height: 55px;
  transition: all 0.3s ease;
  min-width: 215px;
}
.btn:hover {
  background: $white;
  color: $black;
}

.checkbox-item {
  padding-left: 40px;
  display: flex;
  align-items: center;
  .checkbox-radio {
    display: none;
  }
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #1f2544;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 1H3C1.89543 1 1 1.89543 1 3V19C1 20.1046 1.89543 21 3 21H19C20.1046 21 21 20.1046 21 19V3C21 1.89543 20.1046 1 19 1Z' fill='%23FE8400' stroke='%23FE8400' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='bevel'/%3E%3Cpath d='M5.55983 11.8501L8.61983 14.9101L16.4398 7.09009' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      border-radius: 4px;
      left: -40px;
      top: 50%;
      transform: scale(0) rotateZ(180deg) translateY(-50%);
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: transparent;
      border: 1px solid #90a3bf;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.checkbox-radio:checked + label::before {
  transform: scale(1) rotateZ(0deg) translateY(-50%);
}

// .checkbox-radio:checked + label::before {
//   transform: scale(1) rotateZ(0deg);
// }

.big-btn {
  background-color: $orange;
  padding: 12px 0;
  max-width: 255px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  font-size: 24px;
  color: $white;
  line-height: 36px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  &:hover {
    border-color: $orange;
    background-color: $white;
    color: $orange;
  }
}

.car-card {
  padding: 20px 22px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  &-img {
    width: 100%;
    height: 305px;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &-name {
      font-weight: 500;
      font-size: 18px;
      color: #141414;
    }
    &-lists {
      display: flex;
      align-items: center;
      gap: 16px;
      .car-card-info-list {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
        font-size: 15px;
        color: #141414;
      }
    }
    &-book {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
      }
      &-right {
      }
      &-text {
        font-weight: 400;
        font-size: 16px;
        color: #575757;
        margin-bottom: 2px;
      }
      &-price {
        font-weight: 500;
        font-size: 18px;
        color: #141414;
      }
      &-btn {
        font-weight: 400;
        font-size: 15px;
        color: #141414;
        border-radius: 4px;
        background-color: $orange;
        padding: 0 25px;
        height: 40px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        border: 1px solid transparent;
        &:hover {
          border-color: $orange;
          background-color: $white;
          color: $orange;
        }
      }
    }
  }
}

.custom-input {
  padding: 18px 16px;
  background-color: #fbfbfb;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    color: #575757;
  }
}
.custom-textarea {
  padding: 18px 16px;
  background-color: #fbfbfb;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  height: 122px;
  resize: none;
  outline: none;
  &::placeholder {
    color: #575757;
  }
}
.custom-select {
  padding: 18px 16px;
  background-color: #fbfbfb;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  color: #575757;
  outline: none;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L12.5 12.5L24 1' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: right 20px center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearence: none;
  &::placeholder {
    color: #575757;
  }
}
.payment__form {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.payment__form-con {
  display: flex;
  flex-direction: column;
}
.payment__form-con-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}
.payment__form-con-head-step {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #90a3bf;
}
.payment__form-con-title {
  font-size: 20px;
  line-height: 150%;
  color: #1a202c;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: -0.03em;
}
.payment__form-con-text {
  font-size: 14px;
  line-height: 150%;
  color: #90a3bf;
  font-weight: 500;

  letter-spacing: -0.02em;
}
.payment__form-con-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 22px 32px;
}
.payment__form-con-checkbox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
  .payment__form-con-checkbox-itme {
    border-radius: 10px;
    background-color: #f6f7f9;
    padding: 16px 32px;
  }
}
.payment__form-con-safe {
  margin-top: 32px;
  svg {
    margin-bottom: 10px;
  }
  .payment__form-con-title {
    font-weight: 600;
    font-size: 16px;
  }
  .payment__form-con-text {
    font-size: 14px;
  }
}
.payment__form-con-btn {
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  color: #0f0f0f;
  border-radius: 4px;
  background-color: #fe8400;
  padding: 16px 32px;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    border-color: #fe8400;
    background-color: #fff;
    color: #fe8400;
  }
}

.input-con {
  display: flex;
  flex-direction: column;
}
.input-con label {
  font-size: 16px;
  line-height: 24px;
  color: #1a202c;
  font-weight: 600;
  margin-bottom: 15px;
}

.input-con input {
  width: 100%;
  padding: 16px;
  padding-left: 30px;
  background-color: #f6f7f9;
  border-radius: 10px;
  color: #1a202c;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.input-con select {
  width: 100%;
  padding: 16px;
  padding-left: 30px;
  background-color: #f6f7f9;
  border-radius: 10px;
  color: #90a3bf;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  outline: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.0002 9.79963C6.59186 9.79963 6.18353 9.64213 5.87436 9.33297L2.07103 5.52963C1.90186 5.36047 1.90186 5.08047 2.07103 4.9113C2.24019 4.74213 2.52019 4.74213 2.68936 4.9113L6.49269 8.71463C6.77269 8.99463 7.2277 8.99463 7.5077 8.71463L11.311 4.9113C11.4802 4.74213 11.7602 4.74213 11.9294 4.9113C12.0985 5.08047 12.0985 5.36047 11.9294 5.52963L8.12603 9.33297C7.81686 9.64213 7.40853 9.79963 7.0002 9.79963Z' fill='%2390A3BF' stroke='%2390A3BF' stroke-width='0.5'/%3E%3C/svg%3E%0A");
  background-position: right 24px center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearence: none;
  &::placeholder {
    color: #575757;
  }
}

.input-con input::placeholder {
  color: #90a3bf;
}
.input-con span {
  font-size: 12px;
  line-height: 16px;
  color: #e04a33;
  margin-top: 5px;
}
.payment__form-con-card {
  padding: 24px;
  background-color: #f6f7f9;
  border-radius: 10px;
}
.payment__form-con-card .input-con input {
  background-color: #fff;
}
.payment__form-con-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.payment__form-con-card-head.solo {
  margin-top: 32px;
}
.payment__form-con-card-head-left {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #1a202c;
}

.payment__form-con textarea {
  resize: none;
  width: 100%;
  padding: 20px;
  padding-left: 40px;
  background-color: #f6f7f9;
  border-radius: 10px;
  color: #1a202c;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  height: 308px !important;
  outline: none;
}
.payment__form-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.payment__form-submit p {
  color: #1a202c;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.payment__confirm {
  padding: 16px 32px;
  background-color: #5100fe;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
}
.payment__form-con-radio {
  gap: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 500px) {
  .payment__form-con-box {
    grid-template-columns: repeat(1, 1fr);
  }
}

.radiobutton-input {
  visibility: hidden;
  width: 0.01px;
  height: 0.01px;
  overflow: hidden;
  z-index: -10;
}
.radiobutton-label {
  display: flex;
  width: 100%;
}
.radiobutton-label-con {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px 32px;
  border-radius: 4px;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1a202c;
  background-color: #f6f7f9;
}
.custom-radiobutton {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #90a3bf;
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 50%;
  }
}
.radiobutton-input:checked + .radiobutton-label-con .custom-radiobutton::after {
  background-color: $orange;
}
.radiobutton-label-custom {
  margin-left: auto;
  display: flex;
}
